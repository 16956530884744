import React from 'react';
import { useRouter } from 'next/router';
import { Header, HeaderContext } from '@weddingspot/ws-core-components';

const AppHeader = () => {
    const { pathname } = useRouter();
    const { profileVersion } = React.useContext(HeaderContext);

    const showShoppingCart = profileVersion === 'v3';
    return (
        <>
            {pathname === '/' || pathname === '/venue/[...venue]' ? (
                <Header isSticky={true} showShoppingCart={showShoppingCart} />
            ) : (
                <Header showShoppingCart={showShoppingCart} />
            )}
        </>
    );
};

export default AppHeader;
